@import '@assets/styles/abstracts';

.Container {
  position: relative;

  .Title {
    margin-top: 0;
  }

  .ImageWrapper {
    @include aspect(100%, '16:9');
  }
}
